import { useState } from 'react';

export const useInvoicesFilters = invoices => {
  const [currentQualityControllers, setCurrentQualityControllers] = useState([]);
  const [currentOperators, setCurrentOperators] = useState([]);

  const filteredInvoices = invoices
    .filter(
      invoice =>
        !currentQualityControllers.length ||
        currentQualityControllers.includes(invoice.qualityControllerFullName)
    )
    .filter(
      invoice => !currentOperators.length || currentOperators.includes(invoice.ergoUserFullName)
    );

  return [
    filteredInvoices,
    {
      currentQualityControllers,
      setCurrentQualityControllers,
      currentOperators,
      setCurrentOperators,
    },
  ];
};
