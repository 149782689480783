import { api } from './api';

const isOtpRequired = email => api.get(`users/otp_required?email=${email}`);

const login = (email, password) =>
  api.post('users/code_sms', {
    user: {
      email,
      password,
    },
  });

const loginOTP = (email, password, otpAttempt) =>
  api
    .post('users/sign_in', {
      user: {
        email,
        password,
        otpAttempt,
      },
    })
    .then(({ data: { user } }) => user);

const logout = () => api.delete('users/sign_out');

const requestPasswordReset = (email) => 
  api.post('users/password', {
    user: {
      email,
    },
  });

const validateResetToken = (token) =>
  api.get(`users/password/edit?reset_password_token=${token}`);

const resetPassword = ({ reset_password_token, password, password_confirmation }) =>
  api.put('users/password', {
    user: {
      reset_password_token,
      password,
      password_confirmation,
    },
  });

export const authAPI = { isOtpRequired, logout, login, loginOTP, requestPasswordReset, resetPassword, validateResetToken };
