import { invoicesConstants } from './constants';

export const initialState = {
  data: [],
  pending: false,
  meta: null
};

export function invoices(state = initialState, action) {
  switch (action.type) {
    case invoicesConstants.GET_INVOICES_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case invoicesConstants.GET_INVOICES_SUCCESS:
      return {
        ...state,
        data: action.data.invoices,
        meta: action.data.meta,
        pending: false,
      };
    case invoicesConstants.UPDATE_INVOICES_COMMENTS:
      return {
        ...state,
        data: state.data.map(invoice => {
          if (invoice.id === action.invoice) {
            return {
              ...invoice,
              comments: action.folder.comments,
            };
          }
          return invoice;
        }),
      };
    case invoicesConstants.GET_INVOICES_FAILURE:
    default:
      return state;
  }
}
