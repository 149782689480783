import React, { useState } from 'react';
import {
  Box,
  Button,
  Heading,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/core';
import { Formik, useField } from 'formik';
import { useDispatch } from 'react-redux';
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { ErrorMessage } from '../Form/FormInputs';
import { toastActions } from '../../../redux/toast/actions';
import { TOAST_MESSAGE } from '../Toasts/Toast';
import { invoicesAPI } from '../../api/invoices';

const InvoiceSchedulePayment = ({ folder, invoice, successCallback }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  const onModalClose = formik => {
    formik.resetForm();
    onClose();
  };

  const handleSubmit = async (values) => {
    try {
      await invoicesAPI.schedulePayment(invoice.id, { invoice: values });
      dispatch(toastActions.success(TOAST_MESSAGE.success.paymentScheduled));
      successCallback();
      onClose();
    } catch (err) {
      console.error(err);
      dispatch(toastActions.error(TOAST_MESSAGE.error.paymentSchedule));
    }
  };

  return (
    <>
      <Tooltip
        zIndex={1000}
        bg="semiDarkGray.900"
        aria-label="Programmer le paiement"
        label="Programmer le paiement"
        placement="top"
      >
        <IconButton
          icon="calendar"
          variant="ghost"
          variantColor={null}
          onClick={onOpen}
          color="coral.900"
          height={8}
          minW={8}
          rounded="full"
          aria-label="Programmer le paiement"
          _hover={{
            '&:not([aria-disabled])': { color: 'white', backgroundColor: 'coral.900' },
          }}
          _focus={{ shadow: 'none' }}
        />
      </Tooltip>

      <Formik
        initialValues={{ scheduledDate: '' }}
        onSubmit={handleSubmit}
      >
        {formik => (
          <Modal isOpen={isOpen} onClose={() => onModalClose(formik)}>
            <ModalOverlay />
            <ModalContent rounded="lg">
              <ModalHeader as="div" bg="darkGray" color="white">
                <Heading as="h2" fontSize="xl" fontWeight="bold">
                  Programmer le paiement
                </Heading>
              </ModalHeader>
              <ModalCloseButton color="white" _focus={{ shadow: 'none' }} />
              <form onSubmit={formik.handleSubmit}>
                <ModalBody py={5}>
                  <input
                    type="date"
                    id="scheduledDate"
                    name="scheduledDate"
                    onChange={formik.handleChange}
                    value={formik.values.scheduledDate}
                    style={{
                      width: '100%',
                      padding: '8px',
                      borderRadius: '4px',
                      border: '1px solid #E2E8F0'
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <SecondaryButton mr={3} onClick={() => onModalClose(formik)}>
                    Annuler
                  </SecondaryButton>
                  <PrimaryButton type="submit" isLoading={formik.isSubmitting}>
                    Confirmer
                  </PrimaryButton>
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default InvoiceSchedulePayment;
