import React from 'react';
import { Route, Switch } from 'react-router';
import InvoicesTabs from './InvoicesTabs';
import routes from '../common/routes';

export const Invoices = () => {
  return (
    <Switch>
      <Route path={`${routes.invoices.index}/:invoicesType`} component={InvoicesTabs} />
      <Route path={routes.invoices.index} component={InvoicesTabs} />
    </Switch>
  );
};
