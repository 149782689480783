import React from 'react';
import { useFormikContext, Field } from 'formik';
import { Box, Stack, usePrevious, Text } from '@chakra-ui/core';

import { TextInput } from './FormInputs';

const validatePostalCode = (value) => {
  if (!value) return;
  
  // Remove spaces and convert to uppercase
  const cleanValue = value.replace(/\s/g, '').toUpperCase();

  // Rule 1: Length must be 5
  if (cleanValue.length !== 5) {
    return ['Nombre de caractères incorrect'];
  }

  // Rule 2: First, fourth and fifth characters must be digits
  if (!/^\d[\dAB]\d\d\d$/.test(cleanValue)) {
    return ['Format de code postal incorrect'];
  }

  const firstDigit = parseInt(cleanValue[0]);
  const secondChar = cleanValue[1];
  const thirdChar = cleanValue[2];

  // Rule 3: For first digits 0,1,3,4,5,6,7,8, second character must be a digit
  if ([0,1,3,4,5,6,7,8].includes(firstDigit) && !/^\d$/.test(secondChar)) {
    return ['Le second caractère doit être un chiffre'];
  }

  // Rule 4: Special case for Corsica (20XXX)
  if (firstDigit === 2) {
    if (!/[1-9AB]/.test(secondChar)) {
      return ['Attention, le second caractère doit être un A ou un B pour la Corse'];
    }
  }

  // Rule 5: Region code validation
  if (firstDigit === 9 && !['0', '1','2','3','4','5','7'].includes(secondChar)) {
    return ['Le code de région saisie n\'existe pas'];
  }

  // Rule 6: Region code validation
  if (['0','1','2','3','4','5','6','8','9','A','B'].includes(secondChar) && !/^[0-9]$/.test(thirdChar)) {
    return ['Le troisième caractère doit être un chiffre de 1 à 9'];
  }

  // Rule 7: DROM validation
  if (firstDigit === 9 && secondChar === '7' && !['1','2','3','4','5'].includes(thirdChar)) {
    return ['Attention, le 3e numéro doit être un chiffre de 1 à 5 pour les DROM'];
  }
};

export const FormAddressGroup = ({
  addressName,
  postalCodeName,
  cityName,
  disabled,
  isRequired,
}) => {

  const { setFieldValue } = useFormikContext();

  const handlePostalCodeChange = (e) => {
    // Only allow digits, A, B, and spaces
    const value = e.target.value.replace(/[^0-9ABab\s]/g, '');
    setFieldValue(postalCodeName, value);
  };

  return (
    <>
      <TextInput
        label="Adresse"
        type="text"
        id={addressName}
        name={addressName}
        placeholder="3, rue de la paix"
        disabled={disabled}
        requiredInput={isRequired}
      />
      <Stack isInline spacing={4}>
        <Box position="relative" pb={6}>  {/* Added position and padding-bottom */}
          <Field name={postalCodeName} validate={validatePostalCode}>
            {({ field, meta }) => (
              <Box>
                <TextInput
                  label="Code postal"
                  type="text"
                  id={postalCodeName}
                  w="11ch"
                  placeholder="94 000"
                  disabled={disabled}
                  requiredInput={isRequired}
                  onChange={handlePostalCodeChange}
                  {...field}
                />
                {meta.touched && meta.error && (
                  <Text 
                    fontSize="14px" 
                    color="errorBorderColor"
                    position="absolute"
                    top={16}
                    width="200%"
                  >
                    {meta.error}
                  </Text>
                )}
              </Box>
            )}
          </Field>
        </Box>
        <Box flexGrow={1}>
          <TextInput
            label="Ville"
            type="text"
            id={cityName}
            name={cityName}
            placeholder="Créteil"
            disabled={disabled}
            requiredInput={isRequired}
          />
        </Box>
      </Stack>
    </>
  );
};

export const AutoChangeAddress = () => {
  const { values, setValues } = useFormikContext();

  const doesRecipientOwnTheHousing = usePrevious(values.doesRecipientOwnTheHousing);

  React.useEffect(() => {
    if (
      values.doesRecipientOwnTheHousing === 'false' &&
      values.doesRecipientOwnTheHousing !== doesRecipientOwnTheHousing &&
      doesRecipientOwnTheHousing !== undefined
    ) {
      setValues({ ...values, housingStreetAddress: '', housingPostalCode: '', housingCity: '' });
    }
    if (values.doesRecipientOwnTheHousing === 'true' && doesRecipientOwnTheHousing === 'false') {
      setValues({
        ...values,
        housingStreetAddress: values.folder.recipient.streetAddress,
        housingPostalCode: values.folder.recipient.postalCode,
        housingCity: values.folder.recipient.city,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.doesRecipientOwnTheHousing, setValues]);
  return null;
};
