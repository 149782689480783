import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import { Box } from '@chakra-ui/core';

import { PageIndex } from '../common/components/layouts/Page/PageIndex';
import { SearchInput } from '../common/components/Form/FormInputs';
import { SecondaryButton, PrimaryButton } from '../common/components/Buttons';
import {
  Table,
  Thead,
  TH,
  TD,
  THeadRow,
  TBodyRow,
  TablePlaceholder,
  TableEditLink,
  TableResults,
} from '../common/components/Table';
import { operatorsActions } from '../redux/operators/actions';
import { Pagination } from '../common/components/Pagination';

const ConnectedOperatorsIndex = ({ match, getOperators, pending, operators, meta }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const { user } = useSelector(state => state.authentication);

  useEffect(() => {
    let params = {};
    if (user.role === 'ergothérapeute') {
      params = { ...params, byRoles: ['ergothérapeute'], byErgoQualification: ['référent de cabinet', 'ergothérapeute de cabinet'] };
    }
    getOperators(currentPage, search, params);
  }, [getOperators, currentPage, search]);

  return (
    <PageIndex pageAction={<Header match={match} meta={meta} onSearch={name => setSearch(name)} />}>
      <Table>
        <Thead>
          <THeadRow>
            <TH>Rôle</TH>
            <TH>Nom</TH>
            <TH>Prénom</TH>
            <TH>Mail</TH>
            <TH>Téléphone</TH>
          </THeadRow>
        </Thead>
        <tbody>
          {!operators || pending ? (
            <TablePlaceholder />
          ) : (
            operators.map(
              ({ id, role, firstName, lastName, email, phoneNumber, ergoQualification }) => (
                <TBodyRow key={id}>
                  <TD css={{ '*:first-letter ': { textTransform: 'uppercase' } }}>
                    <TableEditLink to={`${match.url}/${id}/edit`}>
                      {(ergoQualification && ergoQualification) || role}
                    </TableEditLink>
                  </TD>
                  <TD textTransform="uppercase">
                    <TableEditLink to={`${match.url}/${id}/edit`}>{lastName}</TableEditLink>
                  </TD>
                  <TD>
                    <TableEditLink to={`${match.url}/${id}/edit`}>{firstName}</TableEditLink>
                  </TD>
                  <TD>
                    <TableEditLink to={`${match.url}/${id}/edit`}>{email}</TableEditLink>
                  </TD>
                  <TD isLast>
                    <TableEditLink to={`${match.url}/${id}/edit`}>{phoneNumber}</TableEditLink>
                  </TD>
                </TBodyRow>
              ),
            )
          )}
        </tbody>
      </Table>
      {meta && (
        <Pagination
          current={currentPage}
          total={meta.total}
          defaultPageSize={meta.perPage}
          onChange={page => setCurrentPage(page)}
        />
      )}
    </PageIndex>
  );
};

const mapStateToProps = ({ operators: state }) => ({
  error: state.profile && state.profile.error,
  pending: state.pending,
  operators: state.data,
  meta: state.meta,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOperators: operatorsActions.getOperators,
    },
    dispatch,
  );

export const OperatorsIndex = connect(mapStateToProps, mapDispatchToProps)(ConnectedOperatorsIndex);

const SearchBar = ({ onSearch }) => {
  return (
    <Formik initialValues={{ search: '' }} onSubmit={values => onSearch(values.search)}>
      {formik => (
        <Box as="form" onSubmit={formik.handleSubmit} d="flex" alignItems="start" flexWrap="wrap">
          <SearchInput name="search" />
          <SecondaryButton type="submit" size="sm" ml={3}>
            Lancer la recherche
          </SecondaryButton>
        </Box>
      )}
    </Formik>
  );
};

const Header = ({ onSearch, match, meta }) => {
  return (
    <>
      <SearchBar onSearch={onSearch} />
      <PrimaryButton as={Link} to={`${match.url}/new`} rightIcon="plus">
        Créer un opérateur
      </PrimaryButton>
      {meta && <TableResults ressource="bénéficiaire" total={meta.total} />}
    </>
  );
};
