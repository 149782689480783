import { invoicesConstants } from './constants';
import { invoicesAPI } from '../../common/api/invoices';
import { errorsActions } from '../errors/actions';

function getInvoices(page, search) {
  function request() {
    return { type: invoicesConstants.GET_INVOICES_REQUEST };
  }
  function success(data) {
    return { type: invoicesConstants.GET_INVOICES_SUCCESS, data };
  }
  function failure(error) {
    return { type: invoicesConstants.GET_INVOICES_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return invoicesAPI.getInvoices(page, search)
      .then(data => {
        console.log('API Response in action:', data);
        dispatch(success(data));
      })
      .catch(error => dispatch(errorsActions.handleErrors(failure)(error)));
  };
}

function updateInvoicesComments(invoice, folder) {
  return { type: invoicesConstants.UPDATE_INVOICES_COMMENTS, invoice, folder };
}

export const invoicesActions = {
  getInvoices,
  updateInvoicesComments,
};
