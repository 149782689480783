import React, { useState } from 'react';
import {
  Box,
  Button,
  Heading,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/core';
import { Formik, useField } from 'formik';
import { useDispatch } from 'react-redux';
import {
    RadioInput,
    RadioGroup,
   ErrorMessage } from '../Form/FormInputs';
import { PrimaryButton, SecondaryButton } from '../Buttons';

import { toastActions } from '../../../redux/toast/actions';
import { TOAST_MESSAGE } from '../Toasts/Toast';
import { invoicesAPI } from '../../api/invoices';

const InvoiceControl = ({ folder, invoice, successCallback, isOpen: externalIsOpen, onClose: externalOnClose }) => {
  const { isOpen: internalIsOpen, onOpen: internalOnOpen, onClose: internalOnClose } = useDisclosure();
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure();

  const { isOpen: isSuccessOpen, onOpen: onSuccessOpen, onClose: onSuccessClose } = useDisclosure();
  const [invoiceStatus, setInvoiceStatus] = useState('');
  const [comment, setComment] = useState('');

  const dispatch = useDispatch();

  // Use external or internal state
  const isOpen = externalIsOpen ?? internalIsOpen;
  const onOpen = externalIsOpen ? () => {} : internalOnOpen;
  const onClose = externalOnClose ?? internalOnClose;

  const onModalClose = formik => {
    formik.resetForm();
    onClose();
  };

  const openModal = index => {
    setComment(invoice.comment || '');
    setInvoiceStatus(invoice.invoiceStatus || '');
    onOpen();
  };

  const onSuccessModalClose = () => {
    onSuccessClose();
    successCallback();
  };
  
  const isFinalizeButtonEnabled = invoiceStatus === 'valid' || comment.length >= 10;

  return (
    <>
      {!externalIsOpen && (
        <Tooltip
          zIndex={1000}
          bg="semiDarkGray.900"
          aria-label="Contrôle de la facture"
          label="Contrôle de la facture"
          placement="top"
        >
          <IconButton
            icon="search"
            variant="ghost"
            variantColor={null}
            onClick={onOpen}
            color="coral.900"
            height={8}
            minW={8}
            rounded="full"
            aria-label="Contrôle de la facture"
            _hover={{
              '&:not([aria-disabled])': { color: 'white', backgroundColor: 'coral.900' },
            }}
            _focus={{ shadow: 'none' }}
          />
        </Tooltip>
      )}

      <SuccessModal
        isOpen={isSuccessOpen}
        onClose={onSuccessModalClose}
        onOpen={onSuccessOpen}
        successCallback={successCallback}
      />

      <Formik
        initialValues={{ 
            invoiceStatus: null,
            comment: null
        }}
        onSubmit={async (values, { setTouched, setErrors, resetForm }) => {
            try {
                await invoicesAPI.controlInvoice(invoice.id, {
                  invoice_status: invoiceStatus,
                  comment,
                });
                setTouched({});
                onClose();
                successCallback();
                dispatch(toastActions.success(TOAST_MESSAGE.success.invoiceControl));
            } catch (err) {
                console.log("ERROR", err)
                dispatch(toastActions.error(TOAST_MESSAGE.error.invoiceControl));
                if (err?.response?.data) {
                    setErrors(err.response.data.errors);
                }
            }
        }}
      >
        {formik => (
          <>
            <ConfirmationModal
              isOpen={isConfirmationOpen}
              onOpen={onConfirmationOpen}
              onClose={onConfirmationClose}
              onSubmit={() => {
                onConfirmationClose();
                formik.submitForm();
              }}
            />
            <Modal isOpen={isOpen} closeOnOverlayClick={false} size="full">
              <ModalOverlay />
              <ModalContent rounded="lg" overflow="hidden" width="90%" height="90%" my="5vh">
                <>
                  <ModalHeader as="div" bg="darkGray" color="white">
                    <Heading as="h2" fontSize="xl" fontWeight="bold">
                      <Text
                        as="span"
                        fontFamily="heading"
                        opacity="0.5"
                        textTransform="uppercase"
                        fontWeight="600"
                      >
                        Dossier n°{' '}
                      </Text>
                      {invoice.folderFormattedId}
                      <Text as="span" d="inline-block" mx={2} opacity="0.65">
                        -
                      </Text>{' '}
                      <Text mt={2} as="small">
                        <Text as="span" fontWeight="semibold" textTransform="uppercase">
                          {invoice.recipientLastName || '-'}
                        </Text>{' '}
                        {invoice.recipientFirstName || '-'}
                      </Text>
                    </Heading>
                  </ModalHeader>
                  <ModalCloseButton color="white" _focus={{ shadow: 'none' }} />
                  <ModalBody p={5} display="grid" gridTemplateColumns="65% 35%" style={{ gap: '1rem' }}>
                    <Box
                      as="iframe"
                      src={invoice?.fileData}
                      width="100%"
                      height="100%"
                      border="1px solid"
                      borderColor="placeholder"
                      rounded="lg"
                    >
                      <Text as="span" d="block" mt={5} textAlign="center">
                        Votre navigateur ne supporte pas l'affichage de fichiers PDF.
                      </Text>
                    </Box>
                    <Box height="100%" display="flex" flexDir="column">
                      <Textarea
                        name="comment"
                        height="100%"
                        onChange={e => setComment(e.target.value)}
                        placeholder="Indiquez ici vos remarques et demandes de modifications éventuelles. Elles seront communiquées telles quelles à l’entreprise."
                        borderColor="lightGray2"
                        disabled={invoiceStatus !== 'additional_request'}
                        _focus={{ outline: 0, borderColor: 'coral.900' }}
                        _invalid={{ borderColor: 'errorBorderColor' }}
                        resize="vertical"
                      />
                      <Box mt={2}>
                        <RadioGroup
                          value={invoiceStatus}
                          name="invoice_status"
                          onChange={e => setInvoiceStatus(e.target.value)}
                          isInline
                          legend="Statut de l’analyse de la facture"
                        >
                          <RadioInput value="valid">Conforme</RadioInput>
                          <RadioInput value="additional_request">Incorrecte</RadioInput>
                        </RadioGroup>
                      </Box>
                    </Box>
                  </ModalBody>
                  <ModalFooter pt={0}>
                    <SecondaryButton type="button" mr={3} onClick={() => onClose()}>
                      Annuler
                    </SecondaryButton>
                    <PrimaryButton
                      type="button"
                      onClick={formik.handleSubmit}
                      disabled={!isFinalizeButtonEnabled}
                    >
                      Finaliser
                    </PrimaryButton>
                  </ModalFooter>
                </>
              </ModalContent>
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
};

export default InvoiceControl;

const ConfirmationModal = ({ isOpen, onSubmit, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay zIndex={2000} />
      <ModalContent rounded="lg" zIndex={2000}>
        <ModalHeader>Confirmation d'envoi de facture</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>
            Attention cette action est irréversible, êtes-vous sûr de vouloir écraser cette facture ?
          </p>
        </ModalBody>

        <ModalFooter>
          <SecondaryButton mr={3} onClick={onClose}>
            Non
          </SecondaryButton>
          <PrimaryButton backgroundColor="error" onClick={onSubmit}>
            Oui
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const SuccessModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent rounded="lg">
        <ModalHeader>Confirmation de l'envoi</ModalHeader>
        <ModalBody>
          <p>Votre facture a bien été envoyée.</p>
        </ModalBody>
        <ModalFooter>
          <PrimaryButton onClick={onClose}>OK</PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
