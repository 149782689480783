import PropTypes from 'prop-types';
import { Formik } from 'formik';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, Icon, Text, useToast } from '@chakra-ui/core';
import { TextInput } from '../common/components/Form/FormInputs';
import { PrimaryButton } from '../common/components/Buttons';
import { PageTitle } from '../common/components/Titles';
import { Logo } from '../common/components/Logo';
import { history } from '../common/helpers/history';
import routes from '../common/routes';
import { toastActions } from '../redux/toast/actions';
import { TOAST_MESSAGE } from '../common/components/Toasts/Toast';
import { authAPI } from '../common/api/auth';
import { useDispatch } from 'react-redux';

const ForgotPassword = ({ error }) => {
  const dispatch = useDispatch();

  const onSubmit = async ({ email }, { setFieldError }) => {
    try {
      await authAPI.requestPasswordReset(email);
      history.push(routes.login);
      dispatch(toastActions.success(TOAST_MESSAGE.success.forgotPassword));
    } catch (error) {
      if (error.response?.status === 404) {
        setFieldError('email', 'Aucun compte BelAvie Manager référencé n\'utilise cette adresse mail, veuillez vérifier l\'élément saisie ou contacter un administrateur');
      } else if (error.response?.data?.errors) {
        setFieldError('email', error.response.data.errors[0]);
      }
    }
  };

  const validate = values => {
    const errors = {};
    if (!values.email) {
      errors.email = ['Champ requis'];
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = ['Format d\'adresse mail non reconnu. Veuillez vérifier l\'adresse mail saisie'];
    }
    return errors;
  };

  return (
    <Box minH="100vh" d="flex" justifyContent="start" alignItems="center" bg="gray" position="relative" flexDirection="column">
      <Box height="39vh" minH="350px" width="100%" shadow="layout" position="absolute" bg="darkGray" zIndex="0" top={0} />
      <Logo width="300px" zIndex="1" mt="120px" mb={20} />
      <Box bg="white" p={16} rounded="20px" shadow="layout" w="100%" maxW="480px" zIndex="1">
        <PageTitle as="h1" fontSize="22px" textAlign="center" fontFamily="body" mb={8}>
          Réinitialisation du mot de passe
        </PageTitle>
        <Text mb={8} color="semiDarkGray.600" textAlign="center">
          Veuillez saisir votre adresse mail ci-dessous. Un mail vous sera renvoyé par la suite avec un lien permettant de réinitialiser votre mot de passe.
        </Text>
        <Formik
          initialValues={{ email: '' }}
          validate={validate}
          onSubmit={onSubmit}
        >
          {formik => (
            <form onSubmit={formik.handleSubmit}>
              <Box>
                <TextInput
                  label="Mail"
                  type="email"
                  id="email"
                  name="email"
                  autoComplete="off"
                  placeholder="ergotherapeute@mail.com"
                  isInvalid={formik.errors.email && formik.touched.email}
                />
                {formik.errors.email && formik.touched.email && (
                  <Box color="error" mt={2} textAlign="center">
                    {formik.errors.email}
                  </Box>
                )}
              </Box>
              <Box textAlign="center" mt={10}>
                <PrimaryButton type="submit" disabled={formik.isSubmitting}>
                  Valider
                </PrimaryButton>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
