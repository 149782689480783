import React, { useState } from 'react';
import {
  Box,
  Button,
  Heading,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/core';
import { Formik, useField } from 'formik';
import { useDispatch } from 'react-redux';
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { ErrorMessage } from '../Form/FormInputs';
import { toastActions } from '../../../redux/toast/actions';
import { TOAST_MESSAGE } from '../Toasts/Toast';
import { invoicesAPI } from '../../api/invoices';

const InvoiceUpload = ({ folder, invoice, successCallback }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure();

  const { isOpen: isSuccessOpen, onOpen: onSuccessOpen, onClose: onSuccessClose } = useDisclosure();

  const dispatch = useDispatch();

  const onModalClose = formik => {
    formik.resetForm();
    onClose();
  };

  const onSuccessModalClose = () => {
    onSuccessClose();
    successCallback();
  };

  const upload = async values => {
    const formData = new FormData();
    formData.append('invoiceFile', values.invoiceFile, 'invoiceFile');

    return invoicesAPI.uploadInvoiceFile(invoice.id, formData);
  };

  const disallowUpload =
    !invoice ||
    invoice.folderState === 'Cloturé' ||
    invoice.status === 'valid' ||
    (invoice.status === 'additional_request' &&
      !invoice.additionalRequestsSentAt);

  console.log("disallowUpload", disallowUpload)

  return (
    <>
      <Tooltip
        zIndex={1000}
        bg="semiDarkGray.900"
        aria-label="Mise en ligne d’une facture"
        label="Mise en ligne d’une facture"
        placement="top"
      >
        <IconButton
          icon="upload"
          variant="ghost"
          variantColor={null}
          onClick={onOpen}
          color="coral.900"
          isDisabled={disallowUpload}
          height={8}
          minW={8}
          rounded="full"
          aria-label="Envoyer une facture"
          _hover={{
            '&:not([aria-disabled])': { color: 'white', backgroundColor: 'coral.900' },
          }}
          _focus={{ shadow: 'none' }}
        />
      </Tooltip>

      <SuccessModal
        isOpen={isSuccessOpen}
        onClose={onSuccessModalClose}
        onOpen={onSuccessOpen}
        successCallback={successCallback}
      />

      <Formik
        initialValues={{ invoiceFile: null }}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setTouched, setErrors, resetForm }) => {
          return upload(values)
            .then(() => {
              setTouched({});
              onModalClose({ resetForm });
              onSuccessOpen();
            })
            .catch(err => {
              dispatch(toastActions.error(TOAST_MESSAGE.error.invoiceUpload));
              if (err?.response?.data) {
                if (typeof err?.response?.data.errors === 'object') {
                  setErrors(err?.response?.data.errors);
                } else {
                  setErrors({ invoiceFile: err?.response?.data.errors });
                }
              } else {
                console.error(err);
              }
            });
        }}
      >
        {formik => (
          <>
            <ConfirmationModal
              isOpen={isConfirmationOpen}
              onOpen={onConfirmationOpen}
              onClose={onConfirmationClose}
              onSubmit={() => {
                onConfirmationClose();
                formik.submitForm();
              }}
            />
            <Modal isOpen={isOpen} onClose={() => onModalClose(formik)} size="full">
              <ModalOverlay />
              <ModalContent rounded="lg" overflow="hidden" maxWidth="60rem" my="5vh">
                <>
                  <ModalHeader as="div" bg="darkGray" color="white">
                    <Heading as="h2" fontSize="xl" fontWeight="bold">
                      <Text
                        as="span"
                        fontFamily="heading"
                        opacity="0.5"
                        textTransform="uppercase"
                        fontWeight="600"
                      >
                        Dossier n°{' '}
                      </Text>
                      {folder.formattedId}{' '}
                      <Text as="span" d="inline-block" mx={2} opacity="0.65">
                        -
                      </Text>{' '}
                      <Text mt={2} as="small">
                        {folder.recipientFullName || '-'}
                      </Text>
                    </Heading>
                    <Text as="small">{invoice.indicator}</Text>
                  </ModalHeader>
                  <ModalCloseButton color="white" _focus={{ shadow: 'none' }} />
                  <ModalBody py={5}>
                    <form
                      id="invoiceUploadForm"
                      onSubmit={e => {
                        if (
                          invoice &&
                          invoice.downloadLink &&
                          !invoice.status
                        ) {
                          e.preventDefault();
                          onConfirmationOpen();
                        } else {
                          formik.handleSubmit(e);
                        }
                      }}
                    >
                      <InvoiceUploadInput
                        id="invoiceFile"
                        name="invoiceFile"
                        invoice={invoice.invoiceUploaded}
                      />
                    </form>
                  </ModalBody>
                  <ModalFooter pt={0}>
                    <SecondaryButton type="button" mr={3} onClick={() => onModalClose(formik)}>
                      Annuler
                    </SecondaryButton>
                    <PrimaryButton
                      type="submit"
                      disabled={disallowUpload}
                      isLoading={formik.isSubmitting}
                      loadingText="Envoi en cours"
                      form="invoiceUploadForm"
                    >
                      Envoyer
                    </PrimaryButton>
                  </ModalFooter>
                </>
              </ModalContent>
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
};

export default InvoiceUpload;

export const InvoiceUploadInput = props => {
  const [field, meta, helpers] = useField(props);
  const [fileName, setFileName] = useState('');
  const [previewURL, setPreviewURL] = useState('');

  const handleOnChange = e => {
    if (!e.target.files[0]) return;
    helpers.setError(null);
    setFileName(e.target.files[0].name);
    setPreviewURL(`${URL.createObjectURL(e.target.files[0])}#toolbar=0`);
    helpers.setValue(e.target.files[0]);
  };

  return (
    <>
      <InvoiceUploadWrapper>
        <Box minH={50}>
          <InvoiceUploadFilename fileName={fileName} invoice={props.invoice} />
          {meta.touched && meta.error && <ErrorMessage p={0}>{meta.error}</ErrorMessage>}
        </Box>
        <input
          type="file"
          accept="application/pdf"
          id={props.id || field.name}
          hidden
          onBlur={field.onBlur}
          onChange={handleOnChange}
        />
        <Button
          htmlFor={props.id || field.name}
          tabIndex={0}
          cursor="pointer"
          color="coral.900"
          variantColor={null}
          leftIcon="upload"
          mt={3}
          bg="select.bg.isSelected"
          _hover={{ bg: 'select.bg.isSelected' }}
          _focus={{ bg: 'select.bg.isSelected' }}
          as="label"
        >
          Sélectionner une facture
        </Button>
      </InvoiceUploadWrapper>
      {previewURL && (
        <Box
          as="embed"
          src={previewURL}
          width="100%"
          height="50vh"
          mt={5}
          border="1px solid"
          borderColor="placeholder"
          rounded="lg"
        />
      )}
    </>
  );
};

export const InvoiceUploadWrapper = ({ children, ...props }) => {
  return (
    <Box bg="lightGray4" rounded="10px" p={6} {...props}>
      {children}
    </Box>
  );
};

export const InvoiceUploadFilename = ({
  fileName,
  placeholder = 'Aucune facture disponible. Déposez un pdf.',
  ...props
}) => {
  return props.invoice.downloadLink && !fileName ? (
    <Link
      fontWeight={600}
      color="coral.900"
      href={props.invoice?.downloadLink || ''}
      target="_blank"
    >
      {props.invoice.filename || props.invoice.sharepointFilename}
    </Link>
  ) : (
    <Text color={fileName ? null : 'placeholder'}>{fileName || placeholder}</Text>
  );
};

const ConfirmationModal = ({ isOpen, onSubmit, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay zIndex={2000} />
      <ModalContent rounded="lg" zIndex={2000}>
        <ModalHeader>Confirmation d'envoi de facture</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>
            Attention cette action est irréversible, êtes-vous sûr de vouloir écraser cette facture ?
          </p>
        </ModalBody>

        <ModalFooter>
          <SecondaryButton mr={3} onClick={onClose}>
            Non
          </SecondaryButton>
          <PrimaryButton backgroundColor="error" onClick={onSubmit}>
            Oui
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const SuccessModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent rounded="lg">
        <ModalHeader>Confirmation de l'envoi</ModalHeader>
        <ModalBody>
          <p>Votre facture a bien été envoyée.</p>
        </ModalBody>
        <ModalFooter>
          <PrimaryButton onClick={onClose}>OK</PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
