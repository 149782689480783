import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex, IconButton, Spinner, Tooltip , Text } from '@chakra-ui/core';
import { connect, useSelector , useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';


import { BaseTabs, TabList, TabWithBadge, TabPanels } from '../common/components/Tabs';
import routes, { getInvoicesPath } from '../common/routes';
import { history } from '../common/helpers/history';
import { TabPanel, INVOICES_TYPES, TBody , THFilter } from './components';
import { invoicesActions } from '../redux/invoices/actions';
import { SearchInput } from '../common/components/Form/FormInputs';
import { SecondaryButton } from '../common/components/Buttons';
import { Table, TBodyRow, TD, TH, Thead, THeadRow } from '../common/components/Table';
import { Pagination } from '../common/components/Pagination';

import InvoiceUpload from '../common/components/InvoiceUpload/InvoiceUpload';
import InvoiceControl from '../common/components/InvoiceControl/InvoiceControl';
import InvoiceSchedulePayment from '../common/components/InvoiceSchedulePayment/InvoiceSchedulePayment';
import { invoicesAPI } from '../common/api/invoices';
import { toastActions } from '../redux/toast/actions';
import { TOAST_MESSAGE } from '../common/components/Toasts/Toast';
import { useInvoicesFilters } from './use-invoices-filters.hook';
import Can from '../roleBasedAccess/Can';

const InvoicesTabs = ({
  match: {
    params: { invoicesType },
  },
  invoices,
  pending,
  getInvoices,
  meta,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const [search, setSearch] = useState({
    keywords: '',
    delay: '',
    tags: [],
    indicators: [],
  });

  useEffect(() => {
    getInvoices(currentPage, {
      keywords: search.keywords,
      status: invoicesType,
    });
  }, [getInvoices, invoicesType, search, currentPage]);

  if (!invoices) return null;

  const tabIndex = routes.invoices.types.indexOf(`/${invoicesType}`);

  function metaCountByType(type) {
    if (!meta) {
      return 'N/A';
    }

    switch (type) {
      case 'green':
        return meta.pendingCount;
      case 'orange':
        return meta.validationsPendingCount;
      case 'red':
        return meta.validatedCount;
      case 'purple':
        return meta.transmittedCount;
      case 'blue':
        return meta.scheduledCount;
      case 'gray':
        return meta.archivedCount;
      default:
        return meta.totalCount;
    }
  }

  return (
    <Box w="100%" px={10} pt={5}>
      <BaseTabs
        height="calc(100% - 36px)"
        defaultIndex={tabIndex >= 0 ? tabIndex : 0}
        onChange={index => history.push(getInvoicesPath(index))}
      >
        <SearchBar onSearch={setSearch} meta={meta} invoicesType={invoicesType} />

        <TabList>
          {Object.entries(INVOICES_TYPES).map(([type, label]) => (
            <TabWithBadge
              key={type}
              badgeBgColor={`tag.secondaryBg.${type}`}
              badgeContent={metaCountByType(type)}
            >
              {label}
            </TabWithBadge>
          ))}
        </TabList>

        <TabPanels pt={8}>
          {Object.keys(INVOICES_TYPES).map(type => (
            <TabPanel key={type} overflowY="hidden">
              <InvoicesTable
                invoices={invoices}
                pending={pending}
                getInvoices={getInvoices}
                meta={meta}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                search={{ ...search, status: invoicesType }}
              />
              {invoicesType === "paiements-programmés" && (
              <Box position="absolute" bottom={0} left={0} px={8} d="flex" alignItems="center" justifyContent="start" mt={4}>
                <Box as="span" color="tag.color.red">*</Box>
                <Text>
                  Un délai de traitement bancaire de 2 à 5 jours ouvrés peut s'appliquer avant la réception effective des fonds.
                </Text>
              </Box>
        )}
            </TabPanel>
          ))}
        </TabPanels>
      </BaseTabs>
    </Box>
  );
};

const mapStateToProps = ({ invoices: state }) => {
  return {
    pending: state.pending,
    invoices: state.data,
    meta: state.meta,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getInvoices: invoicesActions.getInvoices,
    },
    dispatch,
  );

const SearchBar = ({ onSearch }) => {
  const form = useRef();

  return (
    <Flex isInline justifyContent="space-between" w="100%">
      <Formik
        innerRef={form}
        initialValues={{ search: '', delay: '' }}
        onSubmit={values =>
          onSearch({
            keywords: values.search,
            delay: values.delay?.value || values.delay,
          })
        }
      >
        {formik => (
          <Box
            as="form"
            onSubmit={formik.handleSubmit}
            d="flex"
            alignItems="start"
            flexGrow="1"
            minW={0}
            mr={3}
            mb={3}
          >
            <Box d="flex" flexDir="column" width="100%" flexGrow={1} minW={0}>
              <SearchInput name="search" />
            </Box>
            <SecondaryButton type="submit" size="sm" ml={3}>
              Lancer la recherche
            </SecondaryButton>
          </Box>
        )}
      </Formik>
    </Flex>
  );
};

const InvoicesTable = ({
  invoices,
  pending,
  getInvoices,
  meta,
  currentPage,
  setCurrentPage,
  search
}) => {
  const { user } = useSelector(state => state.authentication);
  const isPendingTab = search?.status === 'en-attente';
  const isValidationTab = search?.status === 'validations-en-attente';
  const isWaitingForClosureTab = search?.status === 'validées-en-attente-de-fin-de-mission';
  const isTransmissionTab = search?.status === 'transmises-en-paiement';
  const isScheduledTab = search?.status === 'paiements-programmés';
  const dispatch = useDispatch();
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isControlModalOpen, setIsControlModalOpen] = useState(false);
  const [
    filteredInvoices,
    {
      currentQualityControllers,
      setCurrentQualityControllers,
      currentOperators,
      setCurrentOperators,
    },
  ] = useInvoicesFilters(invoices);

  const qualityControllers = invoices
    .reduce((acc, invoice) => {
      const qualityController = `${invoice.qualityControllerFullName}`;
      if (!acc.includes(qualityController)) {
        acc.push(qualityController);
      }

      return acc;
    }, [])
    .sort();

  const operators = invoices
    .reduce((acc, invoice) => {
      const operator = `${invoice.ergoUserFullName}`;
      if (!acc.includes(operator)) {
        acc.push(operator);
      }
      return acc;
    }, [])
    .sort();

  const handleCancelValidation = async (invoice) => {
    try {
      await invoicesAPI.cancelInvoiceValidation(invoice.id);
      dispatch(toastActions.success(TOAST_MESSAGE.success.invoiceCancelValidation));
      getInvoices(currentPage, search);
      setSelectedInvoice(invoice);
      setIsControlModalOpen(true);
    } catch (err) {
      console.log("ERROR", err);
      dispatch(toastActions.error(TOAST_MESSAGE.error.invoiceCancelValidation));
    }
  };

  const getGridTemplateColumns = () => {
    if (isScheduledTab) {
      return "0.6fr 75px 0.5fr 0.5fr 0.6fr 0.8fr 120px 100px";
    }
    if (isWaitingForClosureTab || isTransmissionTab) {
      return "0.6fr 75px 0.5fr 0.5fr 0.6fr 0.8fr 100px 100px";
    }
    return "0.6fr 75px 0.5fr 0.5fr 0.6fr 0.8fr 100px";
  };

  return (
    <>
      <Table d="flex" flexDirection="column" px={8} h="calc(100% - 52px)">
        <Thead d="block" pos="sticky" zIndex="docked">
          <THeadRow
            d="grid"
            gridTemplateColumns={getGridTemplateColumns()}
          >
            <TH size="sm">Bénéficiaire</TH>
            <TH size="sm">Tag</TH>
            <TH size="sm">
              <THFilter
                options={operators}
                label="Intervenant"
                menuOptionGroupOptions={{ onChange: setCurrentOperators, value: currentOperators }}
              />
            </TH>
            <TH size="sm">
              <THFilter
                options={qualityControllers}
                label="Contrôleur qualité"
                menuOptionGroupOptions={{ onChange: setCurrentQualityControllers, value: currentQualityControllers }}
              />
            </TH>
            <TH size="sm">Indicateur</TH>
            <TH size="sm">Stade de la mission</TH>
            {(isWaitingForClosureTab || isTransmissionTab) && <TH size="sm">Validation</TH>}
            {isScheduledTab && <TH size="sm">Paiement programmé le<Box as="span" color="tag.color.red">*</Box></TH>}
            <TH size="sm">Actions</TH>
          </THeadRow>
        </Thead>
        <TBody>
          {/* eslint-disable-next-line no-nested-ternary */}
          {!invoices || invoices.length === 0 || pending ? (
            pending ? (
              <Box
                d="flex"
                color="semiDarkGray.600"
                mt={8}
                alignItems="center"
                justifyContent="center"
              >
                <Spinner label="Chargement..." />
              </Box>
            ) : (
              <Box
                d="flex"
                color="semiDarkGray.600"
                mt={8}
                alignItems="center"
                justifyContent="center"
              >
                Aucun résultat
              </Box>
            )
          ) : (
            filteredInvoices.map((invoice, index) => {
              const {
                id,
                userTitle,
                folderFormattedId,
                recipientFirstName,
                recipientLastName,
                recipientFullName,
                folderTag,
                ergoUserFullName,
                qualityControllerFullName,
                indicator,
                invoiceMissionStatus,
                validatedAt,
                paymentScheduledAt,
              } = invoice;


              return (
                // eslint-disable-next-line react/no-array-index-key
                <TBodyRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  hasScaleOnHover={false}
                  enableHover={false}
                  d="grid"
                  gridTemplateColumns={getGridTemplateColumns()}
                >
                  <TD>
                    {`${folderFormattedId} - ${userTitle} ${recipientFirstName} ${recipientLastName}`}
                  </TD>
                  <TD>{folderTag}</TD>
                  <TD>{ergoUserFullName}</TD>
                  <TD>{qualityControllerFullName}</TD>
                  <TD>{indicator}</TD>
                  <TD>{invoiceMissionStatus}</TD>
                  {(isWaitingForClosureTab || isTransmissionTab) && <TD>{validatedAt}</TD>}
                  {isScheduledTab && <TD>{paymentScheduledAt}</TD>}
                  <TD>
                    {(isPendingTab || isValidationTab) && (
                      <InvoiceUpload
                        folder={{ formattedId: folderFormattedId, recipientFullName }}
                        invoice={invoice}
                        successCallback={() => getInvoices(currentPage, search)}
                      />
                    )}
                    {isValidationTab && (
                      <Can
                        perform="invoices:control_invoice"
                        yes={() => (
                          <InvoiceControl
                            folder={invoice.folder}
                            invoice={invoice}
                            successCallback={() => getInvoices(currentPage, search)}
                          />
                        )}
                      />
                    )}
                    {(isWaitingForClosureTab || isTransmissionTab || isScheduledTab) && (
                    <Tooltip
                      bg="semiDarkGray.900"
                      label="Voir la facture en ligne"
                      placement="top"
                      zIndex={1000}
                      aria-label="Voir la facture en ligne"
                    >
                      <IconButton
                        as="a"
                        icon="view"
                        variant="ghost"
                        variantColor={null}
                        isDisabled={!invoice.fileDownloadLink}
                        href={invoice.fileDownloadLink}
                        target="_blank"
                        color="coral.900"
                        height={8}
                        minW={8}
                        rounded="full"
                        aria-label="Voir la facture en ligne"
                        _hover={{
                            '&:not([aria-disabled])': {
                              color: 'white',
                              backgroundColor: 'coral.900',
                            },
                          }}
                        _focus={{ shadow: 'none' }}
                      />
                    </Tooltip>
                    )}
                    {isTransmissionTab && (
                      <Can
                        perform="invoices:control_invoice"
                        yes={() => (
                          <InvoiceSchedulePayment
                            folder={{ formattedId: folderFormattedId, recipientFullName }}
                            invoice={invoice}
                            successCallback={() => getInvoices(currentPage, search)}
                          />
                      )}
                      />
                    )}
                    {(isWaitingForClosureTab || isTransmissionTab) && (
                      <Can
                        perform="invoices:control_invoice"
                        yes={() => (
                          <Tooltip
                            zIndex={1000}
                            bg="semiDarkGray.900"
                            aria-label="Annuler la validation de la facture"
                            label="Annuler la validation de la facture"
                            placement="top"
                          >
                            <IconButton
                              icon="lightning"
                              variant="ghost"
                              variantColor={null}
                              onClick={() => {
                              if (window.confirm('Êtes-vous sûr de vouloir annuler la validation de cette facture ?')) {
                                handleCancelValidation(invoice);
                              }
                            }}
                              color="coral.900"
                              height={8}
                              minW={8}
                              rounded="full"
                              aria-label="Annuler la validation"
                              _hover={{
                              '&:not([aria-disabled])': { color: 'white', backgroundColor: 'coral.900' },
                            }}
                              _focus={{ shadow: 'none' }}
                            />
                          </Tooltip>
                        )}
                      />
                    )}
                  </TD>
                </TBodyRow>
              );
            })
          )}
          {selectedInvoice && (
            <Can
              perform="invoices:control_invoice"
              yes={() => (
                <InvoiceControl
                  folder={selectedInvoice.folder}
                  invoice={selectedInvoice}
                  successCallback={() => {
                    getInvoices(currentPage, search);
                    setIsControlModalOpen(false);
                    setSelectedInvoice(null);
                  }}
                  isOpen={isControlModalOpen}
                  onClose={() => {
                    setIsControlModalOpen(false);
                    setSelectedInvoice(null);
                  }}
                />
              )}
            />
          )}
        </TBody>
      </Table>
      {meta && (
        <Pagination
          current={currentPage}
          total={meta.total}
          defaultPageSize={meta.perPage}
          onChange={page => setCurrentPage(page)}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesTabs);
