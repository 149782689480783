import { apiV1 } from './api';
import { encodeQueryArray } from '../utils';

const getInvoices = (page = 1, search) =>
  apiV1
    .get(
      `/invoices?page=${page}&by_keywords=${search.keywords || ''}&by_status=${search.status || ''}`,
    )
    .then(({ data }) => {
      console.log("API RESPONSE")
      console.log(data);
      return data;
    });

const uploadInvoiceFile = (invoiceId, invoice) =>
  apiV1
    .post(`/invoices/${invoiceId}/upload_invoice`, invoice, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => data);

const controlInvoice = (invoiceId, invoice) =>
  apiV1
    .post(`/invoices/${invoiceId}/control_invoice`, invoice)
    .then(({ data }) => data);

const cancelInvoiceValidation = (invoiceId) =>
  apiV1
    .post(`/invoices/${invoiceId}/cancel_validation`)
    .then(({ data }) => data);

const schedulePayment = (invoiceId, invoice) =>
  apiV1
    .post(`/invoices/${invoiceId}/schedule_payment`, invoice)
    .then(({ data }) => data);

export const invoicesAPI = {
  getInvoices,
  uploadInvoiceFile,
  controlInvoice,
  cancelInvoiceValidation,
  schedulePayment,
};
