import PropTypes from 'prop-types';
import { Formik } from 'formik';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, Icon, Text, Stack, FormControl } from '@chakra-ui/core';
import { TextInput, PasswordInput, ErrorMessage } from '../common/components/Form/FormInputs';
import { PrimaryButton } from '../common/components/Buttons';
import { PageTitle } from '../common/components/Titles';
import { Logo } from '../common/components/Logo';
import { history } from '../common/helpers/history';
import routes from '../common/routes';
import { toastActions } from '../redux/toast/actions';
import { authAPI } from '../common/api/auth';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { Notice, NoticeText, NoticeList } from '../common/components/Notice';
import { TOAST_MESSAGE } from '../common/components/Toasts/Toast';
import { useDispatch } from 'react-redux';

const ResetPassword = () => {
  const { token } = useParams();
  const [isValidToken, setIsValidToken] = useState(false);
  const dispatch = useDispatch();
  const formikRef = useRef();

  useEffect(() => {
    const validateToken = async () => {
      try {
        await authAPI.validateResetToken(token);
        setIsValidToken(true);
      } catch (error) {
        history.push(routes.login);
        dispatch(toastActions.error(TOAST_MESSAGE.error.validateResetToken));
      }
    };
    validateToken();
  }, [token]);

  if (!isValidToken) {
    return null;
  }

  const onSubmit = async (values, { setFieldError }) => {
    try {
      await authAPI.resetPassword({
        reset_password_token: token,
        password: values.password,
        password_confirmation: values.passwordConfirmation
      });
      history.push(routes.login);
      dispatch(toastActions.success(TOAST_MESSAGE.success.resetPassword));
    } catch (error) {
      if (error.response?.data?.errors) {
        const firstError = error.response.data.errors[0];
        if (firstError.includes('Mot de passe')) {
          formikRef.current.setFieldError('password', firstError);
        }
      }
    }
  };

  const validate = values => {
    const errors = {};
    if (!values.password) {
      errors.password = ['Champ requis'];
    }
    if (!values.passwordConfirmation) {
      errors.passwordConfirmation = ['Champ requis'];
    } else if (values.password !== values.passwordConfirmation) {
      errors.passwordConfirmation = ['Les mots de passe ne correspondent pas'];
    }
    return errors;
  };

  return (
    <Box minH="100vh" d="flex" justifyContent="start" alignItems="center" bg="gray" position="relative" flexDirection="column">
      <Box height="39vh" minH="350px" width="100%" shadow="layout" position="absolute" bg="darkGray" zIndex="0" top={0} />
      <Logo width="300px" zIndex="1" mt="120px" mb={20} />
      <Box bg="white" p={16} rounded="20px" shadow="layout" w="100%" maxW="480px" zIndex="1">
        <PageTitle as="h1" fontSize="22px" textAlign="center" fontFamily="body" mb={8}>
          Réinitialisation du mot de passe
        </PageTitle>
        <Text mb={8} color="semiDarkGray.600" textAlign="center">
          Veuillez saisir votre nouveau mot de passe ci-dessous.
        </Text>
        <Formik
          initialValues={{ password: '', passwordConfirmation: '' }}
          validate={validate}
          innerRef={formikRef}
          onSubmit={onSubmit}
        >
          {formik => (
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={0}>
                <TextInput
                label="Nouveau mot de passe"
                type="password"
                id="password"
                name="password"
                autoComplete="new-password"
                isInvalid={formik.errors.password && formik.touched.password}
                />
                <TextInput
                  label="Confirmation du mot de passe"
                  type="password"
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  autoComplete="new-password"
                  isInvalid={formik.errors.passwordConfirmation && formik.touched.passwordConfirmation}
                />
              </Stack>
              {formik.errors.password && formik.touched.password && (
                <Text fontSize="14px" pl={3} mb={4} color="errorBorderColor">
                  {formik.errors.password}
                </Text>
              )}
              <Box textAlign="center" mt={6}>
                <PrimaryButton type="submit" disabled={formik.isSubmitting}>
                  Valider
                </PrimaryButton>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default ResetPassword;
